.war-table-dialog {

  .MuiTableCell-root {
    font-size: 14px !important;
    font-weight: bold;
    border: 0;
    padding: 3px;
  }
  .war-table-header {
    padding-bottom: 20px;

    &.with-dropdown {
      font-weight: normal;
      width: 100%;

      > div {
        display: flex;
        align-items: center;

        > div:first-child {
          flex: 1;
          text-align: right;
          padding-right: 10px;
        }

        > div:last-child {
          flex: 6;
        }
      }
    }
  }

  .war-table-label {
    text-align: right;
  }

  .war-table-total {
    color: blue;
  }

  .war-table-percent {
    color: green;
  }

  .war-table-note {
    background-color: #e7f5f7;
    color: #476593;
    font-weight: 400;
    padding: 10px 15px;
    margin: 0 60px;
    display: flex;
    align-items: center;

    span:first-child {
      margin-right: 15px;
    }
  }

  .war-table-footer {
    padding-top: 25px;
    text-align: center;
  }

  tr:nth-child(2) td,
  tr:nth-child(4) td, {
    padding-bottom: 20px;
  }
}

/* Agent Rank Dialog is in a popover with z-index of 10002 */
.MuiAutocomplete-popper {
  z-index: 10003 !important;
}