a {
  text-decoration: underline;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

.approved-by-table  {
  border: 1px solid gray;
  border-collapse: collapse;
  padding: 2px 6px;
  font-family: Roboto, sans-serif;
  font-size: .85rem;
}

.approved-by-table > tr > th  {
  border: 1px solid gray;
  background-color: #f0f0f0;
  padding: 4px 6px;
}

.approved-by-table > tr > td  {
  border: 1px solid gray;
  padding: 2px 6px;
}