
.margin-contribution-report-grid .k-table-tbody td {
  padding: 4px 5px;
}

.margin-contribution-report-header-text {
  font-size: 1.2rem;
  font-weight: bold;
  min-height: 30px;
  position: relative;
  transform: translateY(25%);
}

.margin-contribution-report-header-text-green {
  color: green;
}
