.shipments-styles .MuiDivider-root {
  background-color: #007bff !important;
  height: 3px !important;
}


.tooltiptext {
  visibility: hidden;
  font-size: 0.9rem;
  position: absolute;
  left: 850px;
  top: 172px;
  width: 600px;
  min-width: 400px;
  flex-direction: column;
  background-color: rgba(83,83,83,0.91);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
}
.shipment-details-page_author-owner-text-container {
  flex-grow: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


