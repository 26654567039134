
.tgf-horizontal-nav-bar_container {
  display: flex;
}

.tgf-horizontal-nav-bar {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn {
  display: flex;
  align-items: center;
  float: left;
  color: #708c37;
  text-align: center;
  padding: 7px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border-right: 1px solid rgba(0, 0, 0, 0.23);
}

.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn:last-child {
  border-right: none;
}


.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn:hover {
  color: #708c37;
  background-color: #eaeef1;

}

.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn.tgf-horizontal-nav-bar_link-btn-active {
  background-color: #708c37;
  color: white;
}

.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn.tgf-horizontal-nav-bar_link-btn-active .MuiSvgIcon-root.info-icon-tooltip_icon {
  color: white;
}



.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn-active:hover {
  background-color: #82a240;
  color: white;
}

.tgf-horizontal-nav-bar .tgf-horizontal-nav-bar_link-btn {
  transition: background-color ease .3s;
}
