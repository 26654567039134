.shipments-styles .MuiDivider-root {
  background-color: #007bff !important;
  height: 3px !important;
}

.load-board-title {
  color: #546e7a;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  top:10px;
}
