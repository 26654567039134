
.catch-all-search-input-wrapper-div {
  z-index: 2;
  top: 16px;
  right: 260px;
  position: absolute;
  margin: 0;
  display: grid;
}

.catch-call-search-input {
  width: 200px;
  height: 30px;
}

.catch-call-search-input:hover {
  border: 1px solid black;
}

.loading-p {
  color: gray;
  font-family: Roboto, Arial, sans-serif;
  font-size: .9rem;
  justify-self: center;
}
.dropDownWrapperDiv {
  border-radius: 5px;
  display: grid;
  background-color: white;
  width: 200px;
  min-height: 0;
  max-height: 500px;
  overflow-y:scroll;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  justify-self: center;
}

.dropDownCategoryHeader {
  font-family: Roboto, Arial, sans-serif;
  background-color: #365aa8;
  font-size: 1rem;
  color: white;
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
  transition: .2s;
}
.dropDownCategoryHeader:hover {
  background-color: #6589c7;

}

.dropDownLink {
  justify-self: center;
  font-family: Roboto, Arial, sans-serif;
  display: block;
  text-decoration: none;
  color: #49494a;
  font-size: .86rem;
  padding: 6px 10px 4px 10px;
  visibility: visible;
  opacity: 1;
  transition: .3s;
  border-bottom: 1px solid #e6e5e3;
  box-sizing: content-box;
  max-height: 80px;
  min-height: 0;

}

.dropDownLink:hover {
  color: black;
  background-color: lightgrey;

}

.hidden {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  border: none;
}

.no-results-p {
  text-align: center;
  color: black;
}

.ref-num-bol-number {
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-style: italic;
  background-color: gray;
  color: white;
}


