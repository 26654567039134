.card-body span {
    // font-size: 1.25em;
}
.announcement-text p {
    margin: 0;
}

.tgf-footer {
    background-color: #708c368c;
    border-color: #708c36;
}

.tfg-text-muted {
    color: #000000e6 !important;
    font-weight: bolder;
}

.auto-invoice-count-link {
    color: green;
}

.halted-invoice-count-link {
    color: darkred;
}
