
.info-icon-tooltip {
  font-size: .9rem;
  padding: 0;
  margin: 0;
}

.info-icon-tooltip p {
  margin: 4px;
  line-height: 120%;
}

.MuiSvgIcon-root.info-icon-tooltip_icon {
  color: #708c37;
  height: 16px;
  width: 16px;
  margin: 0 4px;
}
