
// The scss file was never imported until now, so this style below was never in use?
//.inline-control + td{
//  margin-left: 12px;
//  margin-right: 2px;
//}


.shipments-styles .MuiDivider-root {
  background-color: #007bff !important;
  height: 3px !important;
}