$fontSize: 12px;
$lineHeight: 12px;
$gridRowLineHeight: 150%;
$linkTextColor: #007bff;

.k-grid .k-pager .k-pager-info {
    font-size: $fontSize;
    line-height: $lineHeight;
    display: block;
}

.k-grid .tgf-grid-link-cell {
    color: $linkTextColor;
    font-weight: bold;
}

.k-grid-md .k-table-md {
    font-size: $fontSize;
    line-height: $lineHeight;
}



.k-table-tbody td {
    line-height: $gridRowLineHeight !important;
    font-size: $fontSize !important;
}

/// make pointer default if column not sortable.
.tgf-grid-default-cursor .k-link {
    cursor: default;
}

.tgf-grid-default-cursor .k-link:hover {
    cursor: default;
}

.tgf-grid-default-cursor .k-column-title {
    cursor: default;
}

///

/// for tooltips in tgf kendo grid
.tgf-kendo-grid-tooltip {
    font-size: .9rem;
}

.tgf-kendo-grid-tooltip p {
    margin: 4px;
}

.tgf-kendo-grid-tooltip hr {
    padding: 0;
    margin: 2px;
}

///

.load-flags-cell>span {
    padding: 0 3px;
}

// to prevent words from being cut when desired
.k-table-td.show-all-text {
    text-overflow: clip;
    white-space: break-spaces;
    word-break: break-word;
}

///

/// center header column text when desired
.tgf-kendo-grid_column-header_centered .k-cell-inner {
    display: flex;
    justify-content: center;
}

.k-column-title {
    padding: 0 0 8px 4px;
    white-space: nowrap !important;
}
