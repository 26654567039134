table tbody td {
    vertical-align: middle !important;
    // padding: 0 0 0 12px !important;
}

.MuiDialog-paperWidthLg {
    max-width: 850px !important;
}

.shipments-styles .MuiDivider-root {
    background-color: #007bff !important;
    height: 3px !important;
}

.select-product-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.select-product-btn-div {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #708c37;
  font-weight: 500;
}

.select-product-wrapper p {
  margin-right: 10px;
}

.select-product-btn-div:hover {
  cursor: pointer;
  text-decoration: underline;
}