.shipment-financials-payment-history-grid.k-grid.k-grid-md {
  border: 1px solid #e6e6e6;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
}

.shipment-financials-payment-history-grid .k-grid-header .k-grid-header-wrap {
  border: none;
}

.shipment-financials-payment-history-grid .k-grid-header {
display: inline-block;
  border: none;
}

.shipment-financials-payment-history-grid tbody tr td {
  border: none;
  background-color: white;
  padding: 0 5px 5px 12px;
  font-weight: bold;

}

.shipment-financials-payment-history-grid tbody tr:nth-child(odd) td {
 color: blue

}

.shipment-financials-payment-history-grid tr th {
  border: none;
  background-color: white;
  font-size: .8rem;
}

.shipment-financials-payment-history-grid > div {
  border: none;
  background-color: white;
}

.shipment-financials-payment-history-grid .k-column-title {
  padding: 5px 5px 5px 0;
}

.shipment-financials-payment-history-grid.k-grid .k-table-thead {
  font-weight: bold;
  color: #546e7a;
}

