.dense-cell {
  font-size: .9em;
}
.alignRight {
  text-align: right;
}

.MuiDialog-paperWidthLg {
  min-width: 40% !important;
}

.shipments-styles .MuiDivider-root {
  background-color: #007bff !important;
  height: 3px !important;
}