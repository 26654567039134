.popup-title {
    background-color: #595959;
    margin: -30px -17px 0 -17px;
    padding: 3px 17px 3px 17px;
    color: white;
    font-weight: bold;
}

.popup-table-primary-column {
    padding-right: 10px;
}

.terminal-information-table-column-right {
    padding-left: 10px;
}

.terminal-information-box {
    width: auto;
    height: auto;
    border-radius: 6px;
    background-color: #74747422;
    padding: 10px;
}

.column-bold-value {
    font-weight: bold;
    padding-left: 10px;
}

.terminal-information-heading {
    margin: 10px 0;
    font-weight: normal;
}

.carrier-rules-popup {
    width: 650px;
}

.carrier-rules-bullet-header {
    font-weight: bold;
}

.total-cost-popup {
    width: 200px;
}

.total-cost-value {
    text-align: right;
    margin: 0;
}
