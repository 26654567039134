//.bg-warning {
//    background-color: #ffc10794 !important;
//}
//
//.bg-warning td {
//    border-top: 1px solid #feda88 !important;
//    border-bottom: 1px solid #efefef !important;
//    border-right: 1px solid #efefef !important;
//    border-left: 1px solid #feda88 !important;
//}
//
//.adress-book-styles .MuiDivider-root {
//    background-color: #784599 !important;
//    height: 3px !important;
//}

.address-book_associate-dropdown-grid-item .MuiInputBase-root  {
    border-radius: 4px;
    height: 39.3px;
}
.address-book-search {
  display: flex;
  align-items: center;
}