.tgf-icon-tooltip_icon {
  color: #708c37;
  padding: 4px;
  border-radius: 20px;
  cursor: pointer;
}

.tgf-icon-tooltip_icon.tgf-icon-tooltip_icon-disabled {
  color: rgba(0, 0, 0, 0.26);

}

.tgf-icon-tooltip_icon.tgf-icon-tooltip_icon-disabled:hover {
  color: rgba(0, 0, 0, 0.26);
  background-color: unset;
  cursor: default;
}

.tgf-icon-tooltip_icon:hover {
  color: #fff;
  background-color: #708c37;
}

.tgf-icon-tooltip_content {
  font-size: .9rem;
  padding: 0;
  margin: 0;
  line-height: 120%;
}

