
.tracking-container {
  max-height: 258px;
  overflow: auto;
}

.tracking-container > .MuiGrid-item:nth-child(odd) span {
  color: blue;
  font-weight: 500;
}

.tracking-container > .MuiGrid-item:nth-child(even) span {
  color: green;
  font-weight: 500;
}

.hazmat-icon {
  opacity: 60%;
  height: 20px;
  width: 20px;
}

.hazmat-icon:hover {
  opacity: 100%;
  cursor: pointer;
}

.hazmat-details-container {
  display: flex;
  justify-content: center;
  color: #546e7a;
  padding: 0 10px 20px 10px;
  font-size: 13px;
}

.hazmat-details-container table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border: 1px solid #ddd;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.hazmat-details-container table thead th {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  padding: 8px;
}

.hazmat-details-container table tbody td {
  border: 1px solid #ddd;
  padding: 8px;
}

.highlighted {
  color: #2b55ff;
}

.ltl-autotrack__page-info.MuiGrid-item.MuiGrid-root {
  color: #2b55ff;
  font-weight: bold;
  padding: 0 4px;
}

.ltl-autotrack__page-info p {
  margin: 4px;
}

.ltl-autotrack_ref-number-card_send-to-details-btn {
  color: #2b55ff;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.ltl-autotrack_ref-number-card_send-to-details-div {
  display: flex;
  justify-content: space-between;
}
