// Grid containers
.MuiGrid-container.shipment-contents-container {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    margin-bottom: 2px;
    padding-bottom: 10px;
}

.MuiGrid-container.hazmat-container {
    gap: 0 15px;
}

// grid items
.shipment-contents-container .grid-item {
    flex: 1;
    white-space: nowrap;
}

.shipment-contents-container .grid-item.small {
    max-width: 100px;
}

.shipment-contents-container .grid-item.nmfc-code {
  max-width: 110px;
}

.shipment-contents-container .grid-item.package-type {
  max-width: 130px;
}

.shipment-contents-container .grid-item.said-to-contain {
  max-width: 130px;
}

.shipment-contents-container .grid-item.dimensions {
  max-width: 200px;
}

.shipment-contents-container .grid-item.remove-button {
    max-width: 30px;
}

.shipment-contents-container .grid-item.center-text {
    text-align: center;
}

.shipment-contents-container .select-product-btn {
    color: #003cff;
    cursor: pointer;
    margin: -5px 0;
}

.shipment-contents-container .select-product-btn:hover {
    color: #7491ff;
    cursor: pointer;
}

.shipment-contents__input a {
    white-space: nowrap;
}

.shipment-contents__remove-row-btn  {
    border: none;
    color: white;
    background-color: #c2c2c2;
    height: 20px;
    width: 20px;
    font-size: 12px;
    font-weight: bold;
}
.shipment-contents__remove-row-btn:hover {
    background-color: #ff6464;
}

.shipment-contents__row-id {
    position: relative;
    top: 2px;
    left: -11px;
    background-color: #FFFFFF;
    font-size: 14px;
}


@media screen and (max-width: 1500px) {

    .shipment-contents-container .grid-item {
        width: 100%;
        flex-basis: auto;
    }

    .shipment-contents-container .grid-item.small {
        max-width: 100%;
        flex-basis: auto;
    }

    .shipment-contents__input.remove-shipment-content-btn {
        flex: 1;
        display: flex;
        justify-content: start;
        align-self: flex-start;
    }

    .shipment-contents-container .grid-item.package-type {
      max-width: 100%;
    }
}



