.tgf-button.k-button {
    transition: 0.1s ease-in-out;  /* Smooth transition */
    opacity: 1;  /* Normal brightness */
}

.tgf-button.k-button.k-disabled {
    color: #ffffff;
}

.tgf-button.k-button:hover {
    border: 1px solid #202020;
    opacity: .8  /* Brighten the button when hovered */
}
