
.MuiGrid-container.quote-result {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    margin-bottom: 2px;
    display: flex;
    //padding: 28px 10px;
}

.quote-result__display-item_container {
    flex-direction: column;
}



label.total-cost {
    top: -20px;
}

.quote-result__label {
    align-self: start;
}

.quote-result__display-item {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.quote-result__carrier-name {
    font-weight: bold;
}

.quote-result__liability {
    font-weight: bold;
}

.quote-result__transit-days{
    font-weight: bold;
}

.quote-result__delivery-date {
    font-weight: bold;
}

.quote-result__delivery-date {
    font-weight: bold;
}

.quote-result__direct-indirect {
    font-weight: bold;
}

.quote-result__total-cost {
    font-weight: bold;
    font-size: 1.2rem;
}

.quote-result__cannot-continue-text {
    text-align: center;
    font-size: .8rem;
    font-weight: 500;
    color: #2b55ff;
}

// for css grid layout.
.quote-result .grid-container {
    display: grid;
    grid-template: auto auto / repeat(9, 1fr);
}

.quote-result .grid-container.remove-1-column {
    grid-template: auto auto / repeat(8, 1fr);
}

.quote-result .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.quote-result .grid-container .span-2-high {
    grid-row: span 2;
}

