.booking-confirmation-card {
  font-weight: 500;
}

.booking-confirmation-card h2 {
  margin: 0;
}

.booking-confirmation-card .highlighted {
  font-weight: bold;
}