a {
  text-decoration: underline;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

.qa-dialog-table  {
  border: 1px solid gray;
  border-collapse: collapse;
  padding: 2px 6px;
  font-family: Roboto, sans-serif;
  font-size: .85rem;
}

.qa-dialog-table > tr > th  {
  border: 1px solid gray;
  background-color: #f0f0f0;
  padding: 4px 6px;
}

.qa-dialog-table > tr > td  {
  border: 1px solid gray;
  padding: 2px 6px;
}

.qa-review-dialog-select-input {
  border-radius: 5px;
  border: 1px solid gray;
  padding: 2px 6px;
}

.qa-review-dialog-select-input > div:focus {
  background-color: white;
}

.qa-review-dialog-label {
  color: #615f5f !important;
}

.qa-dialog-shipment-p {
  font-size: .9rem;
  font-weight: bold;
}

