.business-day-control-container {


  .business-day-row {
    .business-day-cell:first-child {
      border-left: 1px solid #e3e4e4;
    }
  }

  .business-day-cell {
    text-align: center;
    background-color: #fff;
    padding: 15px 0;
    border-right: 1px solid #e3e4e4;
    border-bottom: 1px solid #e3e4e4;
    font-size: 16px;
    font-weight: 400;
  }

  .business-day-header {
    background-color: #708c37;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    border-top: 1px solid #e3e4e4;
  }
}