.mapping-item-on {
  color: green;
  font-size: larger;
}

.mapping-item-off {
  color: grey;
}

.mapping-warning {
  color: red;
}

.mapping-item-left {
  height: 25px;
  text-align: left;
}

.MuiFormControlLabel-root {
  margin-right:0 !important;
  margin-left:0 !important;;
}

.mapping-container {

}
