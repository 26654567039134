.address-book-styles .MuiDivider-root {
  background-color: #784599 !important;
  height: 3px !important;
}

.current-available-credit-div {
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  margin-left: 5px;
}

.shipper-consignee-duplication-link {
  color: blue;
  cursor: pointer;
}

.shipper-consignee-duplication-link:hover {
  opacity: .5;
}