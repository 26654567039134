// kendo inputs
.k-input {
    border-color: #abaaaa !important;
}

.k-dropdownlist {
    border-color: #abaaaa !important;
}

.k-disabled {
    border-color: #d0cece !important;
    background-color: #dadada !important;
}

.k-datepicker.k-disabled {
    background-color: transparent !important;
}


//

// tgf wrapped kendo inputs

.k-button-group.tgf-button-group {
    display: flex;
    flex-wrap: wrap;
}

.tgf-kendo-combobox {
    width: 100%;
}

.tgf-toggle-button.k-switch-sm {
    width: 40px !important;
}

.tgf-toggle-button.k-switch {

    .k-switch-track {
        width: 15px !important;
        height: 15px !important;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
        border-radius: 7px !important;
        opacity: .75;
    }

    // Thumb ripple
    .k-switch-thumb {
        width: 20px !important;
        height: 20px !important;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
        border-radius: 50% !important;
    }

    // Hover
    &:hover,
    &.k-hover {
        .k-switch-thumb::before {
            display: block !important;
            opacity: .04 !important;
        }
    }

    // Focus
    &:focus,
    &.k-focus {
        .k-switch-thumb::before {
            display: block !important;
            opacity: .12 !important;
        }
    }
}

.tgf-toggle-button.k-switch-on .k-switch-thumb  {
    color: #708c37 !important;
    background-color: #708c37 !important;
}

.tgf-toggle-button.k-switch-off .k-switch-track {
    background-color: #b0b0b0 !important;
}

.tgf-toggle-button .k-switch-thumb {
    border-color: #a4a4a4 !important;
}

.tgf-zip-autocomplete button {
    display: none; /* Hide the collapse button */
}

.hide-combobox-dropdown-button button {
    display: none;
}

//
