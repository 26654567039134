.main-navbar {
    background-color: #708c37;
    border-bottom: 1px solid #101010;
    padding: 5px;
    position: fixed;
    z-index: 1;
    width: 100%;
    color: #FFFFFF;
  }
  
  .nav-link {
    margin: 0 2px;
  }
  
  .main-navbar .nav-link:hover {
    background-color: #101010;
    color: #FFFFFF !important;
    border-radius: 6px;
  }
  
  .navbarLogo {
    height: 45px;
    width: 70px;
  }
  .navbarActive {
    background-color: #101010;
    color: #FFFFFF !important;
    border-radius: 6px;
  }