
.margin-container {
  max-width: 100% !important;
  padding: 8px;
  font-size: .6rem !important;
}

.margin-container .MuiCardContent-root  {
  padding: 0 16px !important;
}

.GM-calculation-card {
  font-size: 1.3rem;
}