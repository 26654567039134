
.collective-costs_cell {
    background-color: white;
    font-weight: bold;
    font-size: 1.2rem;
    height: 40px;
    display: flex;
    align-items: center;
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    column-gap: 5px;
}

.collective-costs_warning {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background-color: #e0f2f6;
    width: 60%;
    height: 40px;
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    font-weight: bold;
}