
.tgf-gradient-title-bar {
  display: flex;
  height: 40px;
  background: linear-gradient(180deg, rgb(248, 248, 248) 0%, rgb(236, 236, 236) 100%);
  border: 1px solid #e3e3e3;
  border-radius: 5px 5px 0 0;
  align-items: center;
}

.tgf-gradient-title-bar p {
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 430;
}