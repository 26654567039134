
.last-shipment-chart_counts-div {
  width: auto;
}

.last-shipment-chart_heading-text {
  text-align: center;
}

.last-shipment-chart_heading-text h3 {
  font-size: 1.05rem;
  font-weight: bold;
  padding: 2px !important;
  margin: 2px !important;
}

.last-shipment-chart_heading-text p {
  font-size: .9rem;
  padding: 2px !important;
  margin: 2px !important;
}

.last-shipment-chart_footer-link {
  text-align: center;
}

#last-shipment-chart_main-link {
  display: inline-block;
  color: blue;
  font-size: .9rem;
  padding: 10px;
}

.last-shipment-chart_count-link {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 5px;
  padding:10px;
  text-decoration: none;
}


.last-shipment-chart_count-link:nth-child(1)  {
  color: green;
}
.last-shipment-chart_count-link:nth-child(3)  {
  color: green;
}
.last-shipment-chart_count-link:nth-child(5)  {
  color: #0095ff;
}
.last-shipment-chart_count-link:nth-child(7)  {
  color: #0095ff;
}
.last-shipment-chart_count-link:nth-child(9)  {
  color: red;
}
