.k-grid th {
  padding: .75rem .75rem;
  border-width: 0 0 1px 1px;
  white-space: normal;
}

.address-book-styles .MuiDivider-root {
  background-color: #784599 !important;
  height: 3px !important;
}

.details-page_collector-link {
  text-decoration: none;
  font-weight: bold;
  color: #007bff;
}

.details-page_collector-link:hover {
  text-decoration: underline;

}