.reportData {
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
.reportData th{
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 5px;
    text-align: center;
}
.reportData td {
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 5px;
    text-align: right;
}