.k-grid th {
  padding: .75rem .75rem;
  border-width: 0 0 1px 1px;
  white-space: normal;
}

.carrier-shipment-filters {
  display: flex;

  > div {
    min-width: 230px;
    padding: 4px;
    align-content: flex-end;
  }

  .view-my-shipments-filter {
    min-width: 150px;
  }
}