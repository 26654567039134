.halt-revert-invoice_grid-button {
    color: black;
    font-weight: bold;
    cursor: pointer;
    opacity: .5;
    font-size: 1.2rem;
    transition: font-size .2s;
}

.halt-revert-invoice_grid-button_halt{
    color: #fa0000;

}

.halt-revert-invoice_grid-button_halt:hover {
    opacity: 1;
    font-size: 1.4rem;
}

.halt-revert-invoice_grid-button_revert {
    color: #01a201;
}

.halt-revert-invoice_grid-button_revert:hover {
    opacity: 1;
    font-size: 1.4rem;
}

.required-warning {
    color: darkred;
    font-style: italic;
}
