
.tgf-kendo-month-picker.k-input {
  border: 1px solid black;
}

.tgf-kendo-month-picker_custom-toggle-div {
  padding: 0;
  margin: 0;
  display: flex;
}

.tgf-kendo-month-picker_custom-toggle-button.k-button {
  padding: 0 4px;
  margin: 0;
}

.tgf-kendo-month-picker_custom-toggle-button:nth-child(1) {
  border-radius: 0;
}

.tgf-kendo-month-picker_custom-toggle-button:nth-child(2) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}