
.user-sessions-grid .k-table-tbody td {
  padding: 4px 5px;
}

.user-sessions-header-text {
  font-size: 1.2rem;
  font-weight: bold;
  min-height: 30px;
  position: relative;
  transform: translateY(25%);
}

.user-sessions-header-text-green {
  color: green;
}
