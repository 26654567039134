.invoice-aging-card_row-header p {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: .9rem;
}

.invoice-aging-card_row p {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 1.1rem;
}

.invoice-aging-card_row .row-item:nth-child(1) p {
    color: green;
}

.invoice-aging-card_row .row-item:nth-child(2) p {
    color: blue;
}

.invoice-aging-card_row .row-item:nth-child(3) p {
    color: red;
}