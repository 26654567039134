

.MuiButton-contained.delete-button {
  background-color: #e73535;
  color: white;
}

.MuiButton-contained.delete-button:hover {
  background-color: #d98e8e;
}

.add-edit-posting_duplicate-btn {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}