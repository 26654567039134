html,
body,
main,
#root {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.hidden {
    max-height: 0 !important;
    opacity: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
    border: none !important;
}

.invisible {
    visibility: hidden !important;
}


a.text-primary {
    color: #007bff;

    &:hover {
        text-decoration: underline;
        color: #0056b3;
    }
}

/*
 Moving truck background class.
 */
.truck-bg {
    background-image: url('./core/media/MovingTruckBackground.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*
  This help with Dropdown and Combobox list showing
  when used in a dialog.
*/
.k-animation-container {
    z-index: 10005 !important;
}

.k-label {
    font-size: 12px !important;
}

.k-grid {
    font-size: 12px !important;
    line-height: 12px !important;
}

.k-grid tbody tr {
    line-height: 10px !important;
}

.k-grid tbody td {
    padding: 3px;
    margin: 2px;
    color: #546e7a;
}

.k-grid .k-grid-header .k-header,
.k-grid .k-grid-header-locked .k-header {
    white-space: normal !important;
}

.k-grid .k-grid-header .k-header .k-link,
.k-grid .k-grid-header-locked .k-header .k-link {
    height: auto !important;
    padding: 5px !important;
    text-align: center !important;
    vertical-align: middle !important;
    font-weight: bold !important;
    color: #546e7a !important;
}

.k-column-title {
    white-space: normal !important;
    padding-bottom: 8px;
}

.k-grid tbody>tr:not(.k-detail-row):hover,
.k-grid tbody>tr:not(.k-detail-row).k-hover {
    background-color: #708c3788 !important;
}

.k-window-content {
    background-color: white !important;
}

.center {
    text-align: center !important;
}

.right {
    text-align: right !important;
}

.left {
    text-align: left !important;
}

.k-window-titlebar-actions .k-button-icon {
    color: black !important;
    background-color: lightgray !important;
}

.k-grid-pager {
    order: -1;
    border-width: 0 0 1px;
}