
.details-list {
  font-size: .8rem;
}

.details-list > li > span {
  font-weight: bold;
}

.tooltip-multiStop p {
  margin: 4px;
}