.boldField {
    font-weight: bold;
}

.centerText {
    text-align: center;
}

.highlighted {
    color: #2b55ff;
}

.quote-summary-card span {
    font-weight: 500;
}

.quote-summary__view-contents-button {
    border: none;
    margin-left: -8px;
    margin-top: -5px;
    color: #2b55ff;
    font-weight: bolder;
    text-decoration: underline;
    position: absolute;
}

.carrier-summary-new-line {
    font-weight: 500;
}

.carrier-summary-card-highlight {
    font-weight: 500;
    color: #708c37;
}

.carrier-summary-new-line {
    padding: 0;
    margin: 0;
}

.warning-text {
    font-weight: bold;
    color: red;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

.quote-wizard__page-info.MuiGrid-item.MuiGrid-root {
  color: #2b55ff;
  font-weight: bold;
  padding: 0 4px;
}

.quote-wizard__page-info p {
  margin: 4px;
}

.ltl-quote_results-page_alert-message p {
  font-size: 11.8px;
  font-weight: bold;
  margin: 4px;
}

