
.shipments-listing-grid .k-table-tbody td {
  padding: 2px 5px;
}

.shipments-listing-grid .k-table-tbody td a {
  line-height: 150%;
}


.shipments-listing-grid .k-table-tbody td span {
  line-height: 150%;
}

.tooltiptext {
  visibility: hidden;
  font-size: 0.9rem;
  position: absolute;
  left: 850px;
  top: 172px;
  width: 600px;
  min-width: 400px;
  flex-direction: column;
  background-color: rgba(83,83,83,0.91);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
}