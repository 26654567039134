.margin-contribution-chart-div {
  width: auto;
  min-height: 240px;
  max-height: 240px;
  text-align: center;
}

.margin-contribution-chart-div .k-chart {
  width: auto;
  min-height: 240px !important;
  max-height: 240px !important;
}


.margin-contribution-chart-heading-text {
  text-align: center;
  padding: 2px !important;
  margin: 2px !important;
}

.margin-contribution-chart-heading-text h3 {
  font-size: 1.05rem;
  font-weight: bold;
  padding: 2px !important;
  margin: 2px !important;
}

.margin-contribution-chart-heading-text  p {
  font-size: .9rem;
  padding: 2px !important;
  margin: 2px !important;
}

.margin-contribution-chart-footer-link {
  text-align: center;
}

.margin-contribution-chart-footer-link p {
  text-align: center;
  padding: 2px !important;
  margin: 2px !important;
}

#margin-contribution-chart-main-link {
  display: inline-block;
  color: blue;
  font-size: .9rem;
  padding: 0;
}

