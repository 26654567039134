.modal-label {
    font-weight: bold;
}

.deactivation-subtitle {
    color: #dc3545;
    font-weight: bold;
}

.assignment-page_info-icon-tooltip.MuiGrid-item.MuiGrid-root{
  margin: 0 4px 0 -4px;
  padding: 0;
}