.tableRow:nth-child(even) {
    background-color: #E8E8E8;
}
.tableRow:hover {
    background-color: #D5D5D5;
}

.address-book-styles .MuiDivider-root {
    background-color: #784599 !important;
    height: 3px !important;
}