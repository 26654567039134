//.card-info {
//    min-height: 240px;
//}
//
//.card-info:hover {
//    background-color: var(--light);
//}

.MuiIconButton-root.Mui-disabled {
    opacity: .5;
}