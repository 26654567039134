.total-business-days-container {
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px !important;
}

.business-day-control-container {
    min-width: 630px;

  .business-day-cell {
    min-width: 90px;
  }
}